import { IUserContext } from '@truckdown/systems';
import { ILocationQueryService } from './ILocationQueryService';
import { ILocationImageManager } from './ILocationImageManager';
import { ILocationActionsService } from './ILocationActionsService';
import { ISearchService } from './Search/ISearchService';
import { INoteManager } from './INoteManager';
import { IPreferenceManager } from './IPreferenceManager';
import { ISearchHistoryService } from './ISearchHistoryService';
import { IServiceTransactionManager } from './IServiceTransactionManager';
import { IBannerService } from './Banners/IBannerService';
import { IPromotionsService } from './Promotions/IPromotionsService';

export type { ILocationQueryService } from './ILocationQueryService';
export type { ILocationImageManager } from './ILocationImageManager';
export type { ILocationActionsService } from './ILocationActionsService';
export type { ISearchService } from './Search/ISearchService';
export type { INoteManager } from './INoteManager';
export type { IPreferenceManager } from './IPreferenceManager';
export type { ISearchHistoryService } from './ISearchHistoryService';
export type { IServiceTransactionManager } from './IServiceTransactionManager';
export type { IBannerService } from './Banners/IBannerService';
export type { IPromotionsService } from './Promotions/IPromotionsService';

export { BannerStatus } from './Banners/BannerStatus';
export type { BannerSearchModel } from './Banners/BannerSearchModel';
export type { BannerViewModel } from './Banners/BannerViewModel';
export type { BannerModel } from './Banners/BannerModel';
export type { BannerActiveSubscriptionModel } from './Banners/BannerActiveSubcriptionModel';

export { PromotionStatus } from './Promotions/PromotionStatus';
export { PromotionType } from './Promotions/PromotionType';
export type { PromotionSearchModel } from './Promotions/PromotionSearchModel';
export type { PromotionModel } from './Promotions/PromotionModel';

export type { AddLocationAnalyticModel } from './Models/AddLocationAnalyticModel';
export type { AddOrEditNoteModel } from './Models/AddOrEditNoteModel';
export type { AddOrEditPreferenceModel } from './Models/AddOrEditPreferenceModel';
export type { Address } from './Models/Address';
export type { AddTransactionModel } from './Models/AddTransactionModel';
export type { ApiResultModel } from './Models/ApiResultModel';
export type { ArrayResultModel } from './Models/ArrayResultModel';
export type { BasicLocationInfo } from './Models/BasicLocationInfo';
export type { Coordinate } from './Models/Coordinate';
export type { DimensionModel } from './Models/DimensionModel';
export type { EntryModel } from './Models/EntryModel';
export type { FactModel } from './Models/FactModel';
export { FindLocationSortOptions } from './Models/FindLocationSortOptions';
export type { FindLocationsUsingAccounts } from './Models/FindLocationsUsingAccounts';
export type { FindLocationsUsingArea } from './Models/FindLocationsUsingArea';
export type { FindLocationsUsingPoint } from './Models/FindLocationsUsingPoint';
export type { FindLocationsUsingTags } from './Models/FindLocationsUsingTags';
export type { FindLocationsUsingPreference } from './Models/FindLocationsUsingPreference';
export type { GeoPolygon } from './Models/GeoPolygon';
export type { GetSearchRequestsModel } from './Models/GetSearchRequestsModel';
export type { GetTransactionsModel } from './Models/GetTransactionsModel';
export type { HoursDisplayModel } from './Models/HoursDisplayModel';
export type { HoursEntryModel } from './Models/HoursEntryModel';
export type { HoursExceptionModel } from './Models/HoursExceptionModel';
export { HoursOfOperationDisplayType } from './Models/HoursOfOperationDisplayType';
export { HoursOfOperationHolidaysCondition } from './Models/HoursOfOperationHolidaysCondition';
export { HoursOfOperationType } from './Models/HoursOfOperationType';
export type { Listing } from './Models/Listing';
export type { ListingLocation } from './Models/ListingLocation';
export type { ListingNote } from './Models/ListingNote';
export type { ListingPhoneNumber } from './Models/ListingPhoneNumber';
export type { ListingProperties } from './Models/ListingProperties';
export type { ListingRatingValue } from './Models/ListingRatingValue';
export type { ListingService } from './Models/ListingService';
export { ListingTemplate } from './Models/ListingTemplate';
export { ListingType } from './Models/ListingType';
export { LocationContactType } from './Models/LocationContactType';
export type { LocationDetail } from './Models/LocationDetail';
export type { LocationHours } from './Models/LocationHours';
export type { LocationModel } from './Models/LocationModel';
export type { LocationPhoto } from './Models/LocationPhoto';
export type { LocationQueryOptions } from './Models/LocationQueryOptions';
export { LocationStatus } from './Models/LocationStatus';
export type { LocationSubscription } from './Models/LocationSubscription';
export { MeasureUnit } from './Models/MeasureUnit';
export { PaymentType } from './Models/PaymentType';
export type { Point } from './Models/Point';
export type { PreferenceModel } from './Models/PreferenceModel';
export { PreferenceType } from './Models/PreferenceType';
export type { Properties } from './Models/Properties';
export type { ResultModel } from './Models/ResultModel';
export type { SearchOptions } from './Models/SearchOptions';
export { SearchRadiusPreference } from './Models/SearchRadiusPreference';
export type { SearchRequestBase } from './Models/SearchRequestBase';
export type { SearchRequestModel } from './Models/SearchRequestModel';
export type { SearchResult } from './Models/SearchResult';
export { SearchType } from './Models/SearchType';
export type { ServiceContactModel } from './Models/ServiceContactModel';
export type { ServiceData } from './Models/ServiceData';
export type { ServiceTransaction } from './Models/ServiceTransaction';
export type { ServiceTransactionMessage } from './Models/ServiceTransactionMessage';
export { ServiceTransactionStatus } from './Models/ServiceTransactionStatus';
export { ServiceTransactionType } from './Models/ServiceTransactionType';
export type { ThrottleResponse } from './Models/ThrottleResponse';
export type { UploadFileResultModel } from './Models/UploadFileResultModel';
export type { ListingUpgradeModel } from './Models/ListingUpgradeModel';
export type { LocationVerificationModel } from './Models/LocationVerificationModel';

export default function (context: IUserContext): void {
    context.registerService({
        name: 'ILocationQueryService',
        activator: async () => {
            var svc = await import(/* webpackChunkName: "LocationQueryService" */ './LocationQueryService');
            return new svc.LocationQueryService(context) as ILocationQueryService;
        }
    });

    context.registerService({
        name: 'ILocationImageManager',
        activator: async () => {
            var svc = await import(/* webpackChunkName: "LocationImageManager" */ './LocationImageManager');
            return new svc.LocationImageManager(context) as ILocationImageManager;
        }
    });

    context.registerService({
        name: 'ILocationActionsService',
        activator: async () => {
            var svc = await import(/* webpackChunkName: "LocationActionsService" */ './LocationActionsService');
            return new svc.LocationActionsService(context) as ILocationActionsService;
        }
    });

    context.registerService({
        name: 'INoteManager',
        activator: async () => {
            var svc = await import(/* webpackChunkName: "NoteManager" */ './NoteManager');
            return new svc.NoteManager(context) as INoteManager;
        }
    });

    context.registerService({
        name: 'IPreferenceManager',
        activator: async () => {
            var svc = await import(/* webpackChunkName: "PreferenceManager" */ './PreferenceManager');
            return new svc.PreferenceManager(context) as IPreferenceManager;
        }
    });

    context.registerService({
        name: 'IServiceTransactionManager',
        activator: async () => {
            var svc = await import(/* webpackChunkName: "ServiceTransactionManager" */ './ServiceTransactionManager');
            return new svc.ServiceTransactionManager(context) as IServiceTransactionManager;
        }
    });

    context.registerService({
        name: 'ISearchHistoryService',
        activator: async () => {
            var svc = await import(/* webpackChunkName: "SearchHistoryService" */ './SearchHistoryService');
            return new svc.SearchHistoryService(context) as ISearchHistoryService;
        }
    });

    context.registerService({
        name: 'ISearchService',
        activator: async () => {
            var svc = await import(/* webpackChunkName: "SearchService" */ './Search/SearchService');
            return new svc.SearchService(context) as ISearchService;
        }
    });

    context.registerService({
        name: 'IBannerService',
        activator: async () => {
            var svc = await import(/* webpackChunkName: "BannerService" */ './Banners/BannerService');
            return new svc.BannerService(context) as IBannerService;
        }
    });

    context.registerService({
        name: 'IPromotionsService',
        activator: async () => {
            var svc = await import(/* webpackChunkName: "PromotionsService" */ './Promotions/PromotionsService');
            return new svc.PromotionsService(context) as IPromotionsService;
        }
    });
}